<template>
  <div style="background: #000; height: 100%;">
    <van-nav-bar 
      title="更多说明" 
      fixed 
      @click-left="$router.go(-1)" 
      placeholder
      left-arrow />

    <div class="container">
      <div class="container-item">
        <p class="container-item-title">如何参与竞拍？</p>
        <ul>
          <li class="container-item-li">1.点击“出价”参与竞拍，价高者得</li>
          <li class="container-item-li">2.竞拍成功，支付货款</li>
          <li class="container-item-li">3.等待商家发货</li>
        </ul>
      </div>

      <div class="container-item">
        <p class="container-item-title">关于保证金？</p>
        <ul>
          <li class="container-item-li">拍品需缴纳保证金后方可参与竞拍，如违约保证金将被扣除，未中拍保证金原路返回。</li>
        </ul>
      </div>

      <div class="container-item">
        <p class="container-item-title">关于违约</p>
        <ul>
          <li class="container-item-li">1、若中拍后逾期未支付货款，保证金全额扣除。</li>
          <li class="container-item-li">2、支付货款后，申请退款，保证金全额扣除</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  
}
</script>

<style lang="less" scoped>
/deep/ .van-hairline--bottom::after {
  border-bottom-width: 0;
}
/deep/ .van-nav-bar {
  background: #000;
  .van-nav-bar__left,
  .van-nav-bar__right {
    padding: 0 15px;
  }
  .van-nav-bar__title,
  .van-icon-arrow-left {
    color: white;
  }
}
.container {
  width: 96%;
  margin: auto;
  background: #FEFAE9;
  margin-top: 20px;
  border-radius: 5px;
  padding: 20px 10px;
  box-sizing: border-box;
  &-item {
    margin-bottom: 20px;
    &-title {
      font-size: 20px;
      margin-bottom: 10px;
    }
    &-li {
      font-size: 14px;
      color: #333;
      margin-bottom: 5px;
    }
  }
}
</style>